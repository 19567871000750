<!-- @Author: panezhang -->
<!-- @Date: 2018-09-04 14:00:33.440 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2024-11-04 12:12:41 -->

<template>
    <div
        :class="fullScreen ? 'full-screen' : ''"
        class="yqg-pdf-viewer"
    >
        <div class="toolbar-container">
            <!-- 监督电话 -->
            <a
                v-if="enableCustomerServicePhone"
                class="customer-service-phone"
                @click="$emit('customer-service-phone')"
            >
                <img src="./img/customer-service-phone.png">
            </a>
            <!-- 在线客服 -->
            <a
                v-if="enableCustomerOnline"
                class="customer-online"
                @click="$emit('customer-online')"
            >
                <img src="./img/customer-online.png">
            </a>

            <div v-if="zoomable">
                <a @click="zoomIn()"><img src="./img/zoom-in.png"></a>
                <a @click="zoomOut()"><img src="./img/zoom-out.png"></a>
            </div>
            <a
                v-if="$root.isWebview && downloadable"
                @click="$emit('download')"
            ><img src="./img/download.png"></a>
        </div>

        <div
            ref="rawViewerContainer"
            class="raw-viewer-container"
        >
            <div
                id="viewer"
                class="pdfViewer raw-viewer"
            />
        </div>
    </div>
</template>

<script>
import globalthis from 'globalthis';

import ua from 'ssr-common/util/webview/ua-parsed';

globalthis.shim();

export default {
    name: 'YqgPdfViewer',

    props: {
        url: {
            type: String,
            required: true
        },

        fullScreen: {
            type: Boolean,
            default: false
        },

        zoomable: {
            type: Boolean,
            default: true
        },

        defaultScale: {
            type: Number,
            default: 1.1
        },

        minScale: {
            type: Number,
            default: 0.25
        },

        maxScale: {
            type: Number,
            default: 10.0
        },

        signature: {
            type: Boolean,
            default: false
        },

        downloadable: {
            type: Boolean,
            default: false
        },

        iosSupport: {
            type: Boolean,
            default: false
        },

        customHeader: {
            type: Object,
            default: () => ({})
        },
        // 可否打电话，是否显示"监督电话"图标
        enableCustomerServicePhone: {
            type: Boolean,
            default: false
        },
        // 可否跳转在线客服，是否显示"在线客服"图标
        enableCustomerOnline: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        pdfUrl() {
            if (this.url && this.url.startsWith('http://')) {
                return this.url.replace(/^http:\/\//, 'https://');
            }

            return this.url;
        }
    },

    watch: {
        async url() {
            await this.open(this.pdfUrl);
        }
    },

    async mounted() {
        if (!this.pdfUrl) {
            return;
        }

        if (ua.isWebview && ua.isIOS && !this.downloadable && !this.iosSupport) {
            location.replace(this.pdfUrl);

            return;
        }

        this.$emit('initViewerStart');
        await this.initViewer();
        this.$emit('initViewerEnd');
        await this.open(this.pdfUrl);
        this.$emit('loaded');
    },

    destroyed() {
        return this.close();
    },

    methods: {
        async initViewer() {
            const PDFLib = await import('pdfjs-dist/legacy/build/pdf');
            const PDFViewerLib = await import('pdfjs-dist/legacy/web/pdf_viewer');

            PDFLib.GlobalWorkerOptions.workerSrc = 'https://indoi18n-web-cdn.easycash.id/upload/admin/bb330054-3514-4727-836a-5537e3d4c484.js';

            const container = this.$el.getElementsByClassName('raw-viewer-container')[0];
            if (!container) throw new Error('raw-viewer-container not found');

            const eventBus = new PDFViewerLib.EventBus();

            // init viewer & history
            const linkService = new PDFViewerLib.PDFLinkService({eventBus});
            const pdfViewer = new PDFViewerLib.PDFViewer({
                eventBus,
                container,
                linkService,
                removePageBorders: true,
                useOnlyCssZoom: true,
                textLayerMode: 0
            });

            const pdfHistory = new PDFViewerLib.PDFHistory({
                linkService,
                eventBus
            });

            linkService.setViewer(pdfViewer);
            linkService.setHistory(pdfHistory);

            eventBus.on('pagesinit', () => {
                // We can use pdfViewer now, e.g. let's change default scale.
                pdfViewer.currentScaleValue = 'auto';
            });

            Object.assign(this, {
                PDFLib,
                PDFViewerLib,

                linkService,
                pdfViewer,
                pdfHistory
            });
        },

        async open(url) {
            const {PDFLib, linkService, pdfViewer, pdfHistory, pdfLoadingTask, customHeader} = this;
            if (pdfLoadingTask) {
                // We need to destroy already opened document
                await this.close();
            }

            // load pdf from remote
            this.$emit('startLoadPdf');
            const loadingTask = PDFLib.getDocument({
                url,
                cMapUrl: '/pdfjs-dist/cmaps/',
                cMapPacked: true,
                httpHeaders: {...customHeader}
            });
            const pdfDoc = await loadingTask.promise;
            pdfViewer.setDocument(pdfDoc);
            linkService.setDocument(pdfDoc);
            pdfHistory.initialize({fingerprint: pdfDoc.fingerprint, resetHistory: true});

            Object.assign(this, {
                pdfDoc,
                pdfLoadingTask: loadingTask
            });
        },

        async close() {
            if (!this.pdfLoadingTask) {
                return;
            }

            await this.pdfLoadingTask.destroy();

            this.pdfLoadingTask = null;
            if (this.pdfDoc) {
                this.pdfDoc = null;
                this.pdfViewer.setDocument(null);
                this.linkService.setDocument(null, null);
            }
        },

        zoomIn() {
            let newScale = this.pdfViewer.currentScale;

            if (newScale < this.maxScale) {
                newScale = (newScale * this.defaultScale).toFixed(2);
                newScale = Math.ceil(newScale * 10) / 10;
                newScale = Math.min(this.maxScale, newScale);
            }

            this.pdfViewer.currentScaleValue = newScale;
        },

        zoomOut() {
            let newScale = this.pdfViewer.currentScale;

            if (newScale > this.minScale) {
                newScale = (newScale / this.defaultScale).toFixed(2);
                newScale = Math.floor(newScale * 10) / 10;
                newScale = Math.max(this.minScale, newScale);
            }

            this.pdfViewer.currentScaleValue = newScale;
        }
    }
};
</script>

<style lang="scss">
@import "~pdfjs-dist/legacy/web/pdf_viewer.css";

.yqg-pdf-viewer {
    &.full-screen {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .toolbar-container {
        z-index: 2;
        position: fixed;
        right: 5%;
        bottom: 5%;

        a {
            display: block;
            height: 40px;
            width: 40px;

            &.customer-service-phone,
            &.customer-online {
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }
        }

        img {
            max-width: 100%;
        }
    }

    .raw-viewer-container {
        position: absolute;
        overflow: auto;
        width: 100%;
        top: 5px;
        bottom: 5px;
        left: 0;
        right: 0;
        -webkit-overflow-scrolling: touch; // 解决 iOS 滑动不流畅问题
    }

    .pdfViewer {
        .page {
            margin-bottom: 5px;
            border: none; // override
            box-shadow: 0 2px 23px -5px rgba(48, 55, 70, 0.15);
        }
    }
}
</style>
